import React, { useEffect, useState } from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

export default function MenuList({ currentUrl, menuConfig, layoutConfig }) {
	const [acessoDom, setAcessoDom] = useState(JSON.parse(window.localStorage.getItem('acesso_dom')));
	const [acessoGalax, setAcessoGalax] = useState(JSON.parse(window.localStorage.getItem('migrando_galax')));
	const [liberadoMigrar, setLiberadoMigrar] = useState(JSON.parse(window.localStorage.getItem('user_liberado_migrar')));

	let menuConfigFiltrado = JSON.parse(JSON.stringify(menuConfig));

	useEffect(() => {
		const handleStorageChange = () => {
			setAcessoDom(JSON.parse(window.localStorage.getItem('acesso_dom')));
			setAcessoGalax(JSON.parse(window.localStorage.getItem('migrando_galax')));
			setLiberadoMigrar(JSON.parse(window.localStorage.getItem('user_liberado_migrar')));
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	if (!acessoGalax || acessoDom || !liberadoMigrar) {
		menuConfigFiltrado.aside.items = menuConfigFiltrado.aside.items.filter(item => item.title !== 'Completar migração');
	}

	if (!acessoDom) {
		menuConfigFiltrado.aside.items = menuConfigFiltrado.aside.items.filter(item => item.title !== 'Gateway de Pagamentos');
	}

	if (!acessoGalax && acessoDom) {
		menuConfigFiltrado.aside.items.forEach(item => {
			if (item.title === 'Gateway de Pagamentos' && item.submenu) {
				item.submenu = item.submenu.filter(subItem => subItem.title !== 'Migração de clientes DOM');
			}
		});
	}

	return menuConfigFiltrado.aside.items.map((child, index) => {
		return (
			<React.Fragment key={`menuList${index}`}>
				{child.section && <MenuSection item={child} />}
				{child.separator && <MenuItemSeparator item={child} />}
				{child.title && (
					<MenuItem
						item={child}
						currentUrl={currentUrl}
						layoutConfig={layoutConfig}
					/>
				)}
			</React.Fragment>
		);
	});

}
