import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { Constants } from "../../utils/Constants";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GrupoPermissaoPage = lazy(() =>
	import("./grupo-permissao/GrupoPermissaoPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const PerfilPage = lazy(() =>
	import("./perfil/PerfilPage")
);

const AgendamentoPage = lazy(() =>
	import("./agendamento/AgendamentoPage")
);

const CaixaPage = lazy(() =>
	import("./caixa/CaixaPage")
);

const ComandaPage = lazy(() =>
	import("./comanda/ComandaPage")
);

const ComandaProdutoPage = lazy(() =>
	import("./comanda-produto/ComandaProdutoPage")
);

const PlanoPage = lazy(() =>
	import("./plano/PlanoPage")
);

const ServicoPage = lazy(() =>
	import("./servico/ServicoPage")
);

const ProdutoPage = lazy(() =>
	import("./produto/ProdutoPage")
);

const FilialPage = lazy(() =>
	import("./filial/FilialPage")
);

const ClientePage = lazy(() =>
	import("./cliente/ClientePage")
);

const ClientePlanoPreAprovado = lazy(() =>
	import("./cliente-plano-pre-aprovado/ClientePlanoPreAprovadoPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const EstoquePage = lazy(() =>
	import("./estoque/EstoquePage")
);

const CategoriaPage = lazy(() =>
	import("./categoria/CategoriaPage")
);

const EmpresaParceiraPage = lazy(() =>
	import("./empresa-parceira/EmpresaParceiraPage")
);

const ClientePlanoPreCancelado = lazy(() =>
	import("./cliente-plano-pre-cancelado/ClientePlanoPreCanceladoPage")
);

const HistoricoCaixasPage = lazy(() =>
	import("./historico-caixas/HistoricoCaixasPage")
);

const ClienteBloqueioPage = lazy(() =>
	import("./cliente-bloqueio/ClienteBloqueioPage")
);

const HorarioFeriadosPage = lazy(() =>
	import("./horario-feriados/HorarioFeriadosPage")
);

const ExibicaoServicosPage = lazy(() =>
	import("./exibicao-servicos/ExibicaoServicosPage")
);

const ExibicaoProfissionaisPage = lazy(() =>
	import("./exibicao-profissionais/ExibicaoProfissionaisPage")
);

const ExibicaoPlanosPage = lazy(() =>
	import("./exibicao-planos/ExibicaoPlanosPage")
);

const ConfiguracaoWhatsAppPage = lazy(() =>
	import("./configuracao-whatsapp/ConfiguracaoWhatsAppPage")
);

const PumpPage = lazy(() =>
	import("./pump/PumpPage")
);

const DpotePage = lazy(() =>
	import("./dpote/DpotePage")
);

const ClienteRecompraPage = lazy(() =>
	import("./cliente-recompra/ClienteRecompraPage")
);

const ContaBancariaPage = lazy(() =>
	import("./financeiro/conta-bancaria/ContaBancariaPage")
);

const TermosUso = lazy(() =>
	import("./termos-uso/TermosUsoPage")
);

const PoliticasPrivacidade = lazy(() =>
	import("./politica-privacidade/PoliticaPrivacidadePage")
);

const CategoriaFinanceiraPage = lazy(() =>
	import("./financeiro/categoria-financeira/CategoriaFinanceiraPage")
);

const FormaPagamentoPage = lazy(() =>
	import("./financeiro/forma-pagamento/FormaPagamentoPage")
);

const ComissoesPage = lazy(() =>
	import("./financeiro/comissoes/ComissoesPage")
);

const MovimentacoesPage = lazy(() =>
	import("./financeiro/movimentacao/MovimentacaoPage")
);

const ContasPagarPage = lazy(() =>
	import("./financeiro/movimentacao/contas-a-pagar/ContasPagarPage")
);

const ContasReceberPage = lazy(() =>
	import("./financeiro/movimentacao/contas-a-receber/ContasReceberPage")
);

const BalancoPage = lazy(() =>
	import("./financeiro/movimentacao/balanco/BalancoPage")
);

const BannerPage = lazy(() =>
	import("./banner/BannerPage")
);

const PromocaoServicoPage = lazy(() =>
	import("./promocao/servico/PromocaoServicoPage")
);

const VersoesPage = lazy(() =>
	import("./versoes/VersoesPage")
);

const EscolaCashbarberPage = lazy(() =>
	import("./escola-cashbarber/EscolaCashbarberPage")
);

const HorariosPico = lazy(() =>
	import("./horario-de-pico/HorarioPicoPage")
);

const HistoricoSaquePage = lazy(() =>
	import("./transacoes-financeiras/HistoricoSaquePage")
);

const RecobrarAssinaturasPage = lazy(() =>
	import("./recobrar-assinaturas/RecobrarAssinaturasPage")
);

const AlterarPlanosPage = lazy(() =>
	import("./alterar-planos/AlterarPlanosPage")
);

export default function HomePage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/agendamento" component={AgendamentoPage} />
				<Route path="/caixa" component={CaixaPage} />
				<Route path="/comandas" component={ComandaPage} />
				<Route path="/comanda-produto" component={ComandaProdutoPage} />
				<Route path="/plano" component={PlanoPage} />
				<Route path="/servico" component={ServicoPage} />
				<Route path="/produtos" component={ProdutoPage} />
				<Route path="/filial" component={FilialPage} />
				<Route path="/cliente" component={ClientePage} />
				<Route path="/pre-aprovado" component={ClientePlanoPreAprovado} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/usuario" component={UsuarioPage} />
				<Route path="/perfil" component={PerfilPage} />
				<Route path="/grupo-permissao" component={GrupoPermissaoPage} />
				<Route path="/estoque" component={EstoquePage} />
				<Route path="/categorias" component={CategoriaPage} />
				<Route path="/clube" component={EmpresaParceiraPage} />
				<Route path="/pre-cancelado" component={ClientePlanoPreCancelado} />
				<Route path="/historico-caixas" component={HistoricoCaixasPage} />
				<Route path="/cliente-bloqueio" component={ClienteBloqueioPage} />
				<Route path="/horario-feriados" component={HorarioFeriadosPage} />
				<Route path="/exibicao-servicos" component={ExibicaoServicosPage} />
				<Route path="/exibicao-profissionais" component={ExibicaoProfissionaisPage} />
				<Route path="/exibicao-planos" component={ExibicaoPlanosPage} />
				<Route path="/configuracao-whatsapp" component={ConfiguracaoWhatsAppPage} />
				<Route path={"/pump"} component={PumpPage} />
				<Route path="/dpote" component={DpotePage} />
				<Route path="/cliente-recompra" component={ClienteRecompraPage} />
				<Route path="/contas-bancarias" component={ContaBancariaPage} />
				<Route path="/categorias-financeiras" component={CategoriaFinanceiraPage} />
				<Route path="/formas-pagamento" component={FormaPagamentoPage} />
				<Route path="/comissoes" component={ComissoesPage} />
				<Route path="/movimentacoes/contas-a-pagar" component={ContasPagarPage} />
				<Route path="/movimentacoes/contas-a-receber" component={ContasReceberPage} />
				<Route path="/termos-de-uso" component={TermosUso} />
				<Route path="/versoes" component={VersoesPage} />
				<Route path="/politica-de-privacidade" component={PoliticasPrivacidade} />
				<Route path="/movimentacoes/balanco" component={BalancoPage} />
				<Route path="/banners" component={BannerPage} />
				<Route path="/promocao/servico" component={PromocaoServicoPage} />
				<Route path="/escola-cashbarber" component={EscolaCashbarberPage} />
				<Route path="/horario-de-pico" component={HorariosPico} />
				<Route path="/transacoes-financeiras" component={HistoricoSaquePage} />
				<Route path="/recobrar-assinaturas" component={RecobrarAssinaturasPage} />
				<Route path="/alterar-assinaturas" component={AlterarPlanosPage} />

				<Redirect to="/error/error-v6" />
			</Switch >
		</Suspense >
	);
}
