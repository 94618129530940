import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-2.scss";

export function ErrorPage2() {
	return (
		<div style={{ flex: 1, backgroundColor: '#B8C6FF', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
			{/* <div className="container" >
				<div className="row" >
					<div className="col-lg-6" >
						<p>Logo aqui</p>

						<p><span>503.</span>Atualização do sistema</p>

						<h1>Estamos realizando atualizações no sistema!</h1>

						<p>Desculpe pelo transtorno! Nosso sistema está temporariamente em manutenção para melhorias. Voltaremos em breve para continuar atendendo você. Agradecemos pela sua paciência!</p>
					</div>

					<div className="col-lg-6" >
						<p>Imagem</p>
					</div>
				</div>
			</div> */}
			<div style={{ width: '80vw', flexDirection: window.innerWidth < 960 ? 'column' : 'row', display: 'flex' }}>
				<div style={{ flex: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column', padding: '20px' }}>
					{/* <p>Logo aqui</p> */}
					<img style={{ width: '338px', marginBottom: '56px' }} src={`${toAbsoluteUrl("/media/error/logo_dark_1.png")}`}></img>

					<p style={{ marginBottom: '56px', fontWeight: 400, fontSize: 24 }} ><span style={{ color: '#3E3EC4', fontWeight: 600 }} >503.</span> Atualização do sistema</p>

					<h1 style={{ fontWeight: '700', fontSize: '24px', color: '#222222', marginBottom: '56px' }} >Estamos realizando atualizações no sistema!</h1>

					<p style={{ fontWeight: '400', fontSize: '20px', color: '#1B1C1F' }} >Desculpe pelo transtorno! Nosso sistema está temporariamente em manutenção para melhorias. Voltaremos em breve para continuar atendendo você. Agradecemos pela sua paciência!</p>
				</div>

				<div style={{ display: 'flex', flex: 1, padding: '20px', alignItems: 'center' }} >
					<img style={{ width: '537px' }} src={`${toAbsoluteUrl("/media/error/img2.png")}`}></img>
				</div>
			</div>
		</div>
	);
}
